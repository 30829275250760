
.bg-soft-footer {
    background-color: rgba(32, 41, 66, 0.1) !important;
    border: 1px solid rgba(32, 41, 66, 0.1) !important;
    color: #202942 !important;
  }
  
  .text-footer {
    color: #202942 !important;
  }
  
  a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  a.text-footer:hover, a.text-footer:focus {
    color: #181e30 !important;
  }
  

  .back-to-top {
    z-index: 99;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  
  .back-to-top .icons {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .back-to-top:hover {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  .back-to-top:hover .icons {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .back-to-home {
    position: fixed;
    top: 4%;
    right: 2%;
    z-index: 1;
  }

  .footer {
    background: #202942;
    padding: 60px 0;
    position: relative;
    color: #adb5bd;
  }
  
  .footer .logo-footer {
    font-size: 22px;
  }
  
  .footer .logo-footer:focus {
    outline: none;
  }
  
  .footer .footer-head {
    letter-spacing: 1px;
    font-weight: 500;
  }
  
  .footer .foot-subscribe .form-control {
    background-color: #27314f;
    border: 1px solid #27314f;
    color: #f8f9fc;
  }
  
  .footer .foot-subscribe .form-control:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .footer .foot-subscribe.foot-white .form-control {
    color: #adb5bd;
  }
  
  .footer .foot-subscribe input::-webkit-input-placeholder {
    color: #adb5bd;
  }
  
  .footer .foot-subscribe input:-ms-input-placeholder {
    color: #adb5bd;
  }
  
  .footer .foot-subscribe input::-ms-input-placeholder {
    color: #adb5bd;
  }
  
  .footer .foot-subscribe input::placeholder {
    color: #adb5bd;
  }
  
  .footer .text-foot {
    color: #adb5bd;
  }
  
  .footer .footer-list {
    margin-bottom: 0;
  }
  
  .footer .footer-list li {
    margin-bottom: 10px;
  }
  
  .footer .footer-list li a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  .footer .footer-list li a:hover {
    color: #e6e8ea;
  }
  
  .footer .footer-list li:last-child {
    margin-bottom: 0;
  }
  
  .footer.footer-border, .footer.footer-bar {
    border-top: 1px solid #283353;
  }
  
  .footer.footer-bar {
    padding: 30px 0;
  }